import { createRoot } from '@/createRoot.tsx'
import { TurtleTheme, useTurtleTheme } from '@dmm-com/turtle-components'
import { css } from '@emotion/react'
import { BrandButton } from '@/components/common/BrandButton.tsx'
import { Footer } from '@/components/common/Footer.tsx'
import { MessageAlerts } from '@/components/common/MessageAlerts.tsx'
import { useDjangoCtx } from '@/hooks/useDjangoCtx'

const SPPage = () => {
  const theme = useTurtleTheme()
  const styles = createStyles(theme)
  const { alert_messages } = useDjangoCtx()

  return (
    <div css={styles.wrapper}>
      <header css={styles.header}>
        <a
          href="/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center"
          aria-label="DMM.make 3Dプリント 法人受発注サービス ロゴ"
        >
          <img
            src="/static/images/logo.svg"
            alt="DMM.make 3Dプリント 法人受発注サービス"
            draggable="false"
          />
        </a>
      </header>
      <div css={styles.inner}>
        <div css={styles.content}>
          <MessageAlerts messages={alert_messages} />
          <div css={styles.heading}>
            <h1 css={styles.h1}>
              本サービスは、スマートフォン端末からはご利用いただけません。
            </h1>
            <p css={styles.subtitle}>
              お手数ですが、パソコン端末から本サービスをご利用くださいませ。
            </p>
          </div>
          <section css={styles.section}>
            <div css={styles.mainContent}>
              <h2 css={styles.h3}>
                DMM.make 3D PRINT
                <br />
                法人受発注サービスとは
              </h2>
              <div>
                <p css={styles.body1}>
                  本サービスは、お手持ちの3Dデータをアップロードするだけで、お見積りから発注・3Dプリント造形出力・ご納品までの全ての手続きがWeb上で完結します。
                </p>
                <p css={styles.body1}>
                  Web（ウェブ）から、見積書、発注書、納品書、請求書をダウンロードすることもできます。
                </p>
              </div>
              <BrandButton
                css={styles.linkBtn}
                label={''}
                type={'link'}
                size="large"
                Link={() => (
                  <a href="https://make.dmm.com/blog/" target="_blank">
                    みんすり情報局で詳細を見る
                    <span className="material-icons-round">open_in_new</span>
                  </a>
                )}
              />
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const createStyles = (theme: TurtleTheme) => {
  const { spacing } = theme.turtle
  return {
    wrapper: css`
      width: 100vw;
      min-height: 100vh;
      background-color: ${theme.turtle.palette.backgroundColor
        .primaryBackground};
      display: flex;
      flex-direction: column;
    `,
    inner: css`
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      flex: 1;
    `,
    content: css`
      max-width: 1000px; // 内部コンテンツのmaxは1000pxで固定
      display: flex;
      flex-direction: column;
      gap: ${spacing.medium[3]};
      padding: ${spacing.medium[1]} ${spacing.small[5]};
      color: ${theme.turtle.palette.onSurfaceColor.highEmphasis};
    `,
    header: css`
      padding: ${spacing.medium[1]};
      background-color: ${theme.turtle.palette.surfaceColor.primarySurface};
      position: sticky;
      top: 0;
    `,
    heading: css`
      display: flex;
      flex-direction: column;
      gap: ${spacing.small[5]};
      white-space: pre-wrap;
    `,
    h1: css`
      font-size: ${theme.turtle.typography.heading4.fontSize};
      font-weight: ${theme.turtle.typography.heading4.fontWeight};
      line-height: ${theme.turtle.typography.heading4.lineHeight};
      white-space: pre-wrap;
    `,
    subtitle: css`
      ${theme.turtle.typography.body1};
    `,
    h3: css`
      font-size: ${theme.turtle.typography.heading4.fontSize};
      font-weight: ${theme.turtle.typography.heading4.fontWeight};
      line-height: ${theme.turtle.typography.heading4.lineHeight};
      text-align: left;
      white-space: pre-wrap;
    `,
    body1: css`
      ${theme.turtle.typography.body1};
      margin-bottom: ${theme.turtle.spacing.small[2]};
    `,
    link: css`
      color: ${theme.turtle.palette.interactiveColors.link};
    `,
    section: css`
      background-color: ${theme.turtle.palette.surfaceColor.primarySurface};
      border-radius: ${theme.turtle.borderRadius.small};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: ${spacing.medium[1]};
    `,
    mainContent: css`
      display: flex;
      flex-direction: column;
      gap: ${spacing.small[5]};
    `,
    mainMessage: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    linkBtn: css`
      margin: 0 auto;
    `,
  }
}

createRoot(<SPPage />)
